import { differenceInDays } from 'date-fns';

export type AccessInfo = {
  accessCount: number;
  lastShownAt: number;
};

type Params = {
  accessInfo: AccessInfo;
};

const PLAN_MODAL_TRIGGER_COUNT = 3;

export const checkPlansModalShown = ({ accessInfo }: Params) => {
  const { accessCount, lastShownAt } = accessInfo;

  const diffInDays = differenceInDays(new Date(), new Date(lastShownAt));

  return diffInDays >= 1 && accessCount >= PLAN_MODAL_TRIGGER_COUNT;
};
